import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@baseapp-frontend/core'
import { Box } from '@mui/material'

import { trustedByConstants } from './constants'
import ImagesContainer from './ImagesContainer'
import LoadingState from './LoadingState'
import {
  OuterContainer,
  TrustedByContainer,
  TrustedByContainerRight,
  TrustedByTitle,
  TrustedByWrapper,
} from './styled'

import TrustedByApi from 'api/TrustedByApi'
import { trustedBySectionId } from 'shared/constants'

const TrustedBySection = () => {
  const { data, isFetching } = useQuery('trustedBy', () => TrustedByApi.getTrustedBy())
  const trustedBy = useMemo(() => data?.results || [], [data])

  const [imagesContainerWidth, setImagesContainerWidth] = useState(0)
  const [finalWidth, setFinalWidth] = useState(0)

  useEffect(() => {
    setImagesContainerWidth(trustedBy.length * trustedByConstants.logoWidth)
    window.addEventListener('resize', () => {
      setImagesContainerWidth(trustedBy.length * trustedByConstants.logoWidth)
    })

    return () => {
      window.removeEventListener('resize', () => {
        setImagesContainerWidth(trustedBy.length * trustedByConstants.logoWidth)
      })
    }
  }, [trustedBy])

  useEffect(() => {
    const containerWidth =
      trustedBy.length > 0 ? ((imagesContainerWidth * 100) / window.innerWidth) * 2 : 0

    const newWidth =
      containerWidth < 200
        ? 200 + trustedBy.length * (trustedByConstants.gap / 4)
        : containerWidth + trustedBy.length * trustedByConstants.gap
    setFinalWidth(newWidth)
  }, [imagesContainerWidth, trustedBy.length])

  return (
    <Box component="div" id={trustedBySectionId}>
      {isFetching || !trustedBy.length ? (
        <LoadingState />
      ) : (
        <TrustedByWrapper>
          <TrustedByTitle variant="h3" component="h2">
            Trusted by
          </TrustedByTitle>
          <OuterContainer>
            <TrustedByContainerRight finalwidth={finalWidth} elements={trustedBy.length}>
              <Box component="div" display="flex">
                <ImagesContainer trustedBy={trustedBy} />
                <ImagesContainer trustedBy={trustedBy} />
              </Box>
            </TrustedByContainerRight>
          </OuterContainer>
          <OuterContainer>
            <TrustedByContainer finalwidth={finalWidth} elements={trustedBy.length}>
              <Box component="div" display="flex">
                <ImagesContainer trustedBy={trustedBy} />
                <ImagesContainer trustedBy={trustedBy} />
              </Box>
            </TrustedByContainer>
          </OuterContainer>
        </TrustedByWrapper>
      )}
    </Box>
  )
}

export default TrustedBySection
