import { styled, Box, Typography } from '@mui/material'
import { trustedByConstants } from './constants'
import { ITrustedByContainer } from './types'

export const TrustedByWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  textAlign: 'center',
}))

export const TrustedByTitle = styled(Typography)(() => ({
  margin: '0 auto 36px',
})) as typeof Typography

export const TrustedByContainer = styled(Box)<ITrustedByContainer>(({ finalwidth, elements }) => ({
  width: `${finalwidth}%`,
  animation: `trustedByAnimation ${elements * trustedByConstants.timePerLogo}s linear infinite`,
  position: 'relative',
  overflow: 'hidden',
  '@keyframes trustedByAnimation': {
    '0%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(-50%, 0)',
    },
  },
}))

export const TrustedByContainerRight = styled(Box)<ITrustedByContainer>(
  ({ finalwidth, elements }) => ({
    width: `${finalwidth}%`,
    animation: `trustedByAnimationRight ${
      elements * trustedByConstants.timePerLogo
    }s linear infinite`,
    position: 'relative',
    overflow: 'hidden',
    '@keyframes trustedByAnimationRight': {
      '0%': {
        transform: 'translateX(-50%)',
      },
      '100%': {
        transform: `translateX(0)`,
      },
    },
  }),
)

export const OuterContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::before, &::after': {
    background: 'linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0))',
    content: '""',
    height: '200px',
    width: 0,
    position: 'absolute',
    zIndex: 2,
    [theme.breakpoints.up(1280)]: {
      width: 'calc((100vw - 1280px)*0.5)',
    },
  },
  '&::before': {
    left: 0,
    top: 0,
  },
  '&::after': {
    right: 0,
    top: 0,
    transform: 'rotateZ(180deg)',
  },
}))
